import React, { useState } from 'react';
import NavbarComponent from '../navbar';
import SEO from '@components/commons/seo';
import FooterComponent from '../footer';
import RequestMeetingModal from '../../../template-5/request-meeting';

const Layout = ({ children, seoData, businessData, faqs, ...rest }) => {
    const [openRequestMeeting, setOpenRequestMeeting] = useState(false);
    const headerNavigations = [
        {
            title: 'Home',
            path: '/',
            show: true,
        },
        {
            title: 'Contact Us',
            path: '/#contact',
            show: false,
        },

        {
            title: 'Services',
            path: '/#services',
            show: businessData?.highlightedServices && true,
        },

        {
            title: 'Reviews',
            path: '/#testimonials',
            show: !!businessData?.feedbacks?.length && true,
        },
        {
            title: 'FAQs',
            path: '/faq',
            show: !!faqs?.faqs?.length && true,
        },
    ];

    return (
        <div className="overflow-hidden template-3">
            <SEO data={seoData} />
            <NavbarComponent
                {...rest}
                setOpenRequestMeeting={setOpenRequestMeeting}
                navigations={headerNavigations}
                data={businessData}
            />
            <RequestMeetingModal
                setOpenRequestMeeting={setOpenRequestMeeting}
                openRequestMeeting={openRequestMeeting}
                urlHubspot={process.env.GATSBY_HUBSPOT_MEETING_URL}
            />
            <main>
                <div className="max-w-screen-2xl mx-auto">{children}</div>
            </main>
            <FooterComponent
                {...rest}
                classNames="grid grid-cols-1 xl:block px-20px pb-30px md-1:py-12 mx-auto sm:px-6 md-1:pt-0 md-1:pb-7
            xl:py-16 md-1:px-10 xl:pr-98px xl:pl-98px 3xl:pl-390px 3xl:pr-390px xs:text-center xl:py-0px"
                data={businessData}
            />
        </div>
    );
};

export default Layout;
