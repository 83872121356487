import React from 'react';
import data from '@data/template-5/navigations.json';
import iconFacebook from '@assets/icons/template-3/facebook.svg';
import iconInstagram from '@assets/icons/template-3/instagram.svg';
import iconPinterest from '@assets/icons/template-3/pinterest.svg';
import iconHouse from '@assets/icons/template-3/house.svg';
import iconHouzz from '@assets/icons/template-3/house.svg';
import iconLinkedin from '@assets/icons/template-3/linkedin.svg';
import { nanoid } from 'nanoid';

const FooterComponent = ({ data: strapiData, ...props }) => {
    const { socialProfiles } = strapiData;
    const footerNavigations = data.footer;

    const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('intagram')) {
            return iconInstagram;
        }
        if (key.includes('house')) {
            return iconHouse;
        }
        if (key.includes('houzz')) {
            return iconHouzz;
        }
        if (key.includes('pinterest')) {
            return iconPinterest;
        }

        return iconLinkedin;
    };

    return (
        <footer className="w-full overflow-hidden bg-white" aria-labelledby="footerHeading">
            <div
                className={
                    props.classNames ||
                    `px-20px pt-60px pb-30px md-1:py-12 mx-auto sm:px-6 md-1:pt-0 md-1:pb-7
            xl:py-16 md-1:px-10 xl:pr-98px xl:pl-98px 3xl:pl-390px 3xl:pr-390px`
                }
            >
                <div className="py-30px border-t border-gray-200 flex-1 md-1:flex md-1:items-center md-1:justify-between">
                    <div className="item-copyright-left  grid grid-cols-1 xl:block">
                        {footerNavigations?.links.map((item) => {
                            return (
                                <a
                                    rel="noopener"
                                    className="mr-5 text-sm not-italic font-normal tracking-normal text-gray-400
                                    md-1:text-left leading-6 md-1:mt-0 xs:mt-5"
                                    key={nanoid()}
                                    href={item.link}
                                    target="_blank"
                                >
                                    {item.title}
                                </a>
                            );
                        })}
                    </div>
                    <div className="item-copyright-right grid grid-cols-1 md-1:flex mt-30px md-1:mt-0px">
                        <div className="grid-cols-1 ml-29px xl:mt-0 xl:mr-104px xl:justify-self-end">
                            <div className="items-center">
                                <div className="grid grid-cols-4 cursor-pointer justify-start">
                                    {socialProfiles.map((item) => {
                                        return (
                                            <a href={item.url} target="_blank" rel="noopener">
                                                <img
                                                    key={nanoid()}
                                                    src={handleRenderIcon(item.url)}
                                                    alt={`icon-${item.url}`}
                                                />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        {footerNavigations?.power && (
                            <p
                                className="text-sm not-italic font-normal tracking-normal
                            text-left leading-6 md-1:mt-0 xs:mt-30px text-sm text-gray-400 text-center place-self-center"
                            >
                                {footerNavigations.power}{' '}
                                <a href="https://www.trades.org" target="_blank" rel="noopener">
                                    trades.org
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default FooterComponent;
