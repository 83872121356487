import React, { Fragment, useEffect, useState } from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Popover, Transition } from '@headlessui/react';
import { MailIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import iconSearchHeader from '@assets/icons/search-header.svg';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import Img from 'gatsby-image';
import { Button } from '@tradesorg-components/component-library-waas-templates.base-button';
import styles from './index.module.scss';
import { PhoneIcon } from '@heroicons/react/solid';
import StyledLogo from '@components/commons/styled-logo';

const Logo = ({ logo }) => {
    return (
        <Link to="/" aria-current="site-logo" className="flex">
            <div className={` items-center text-center grid font-extrabold ${styles.logo}`}>
                <Img className="xl:w-40  xs:w-20" fluid={logo} />
            </div>
        </Link>
    );
};

const ItemsMenuLeft = ({ navigations, setOpen, setOpenRequestMeeting }) => {
    return (
        <>
            <Popover.Group
                as="nav"
                className="flex xs:space-x-0 xl:space-y-0 xs:space-y-5 xl:space-x-10 xl:flex xs:grid grid-cols-1 w-full md-1:w-1/2 xl:w-auto"
            >
                { navigations?.map((item) => {
                    if(item.show){
                        return (
                            <AnchorLink
                                key={nanoid()}
                                to={item.path}
                                title={item.title}
                                className="text-normal md-1:text-2xl xl:text-base not-italic font-semibold xl:font-normal
                                tracking-normal text-left text-gray-600 leading-loose  xl:leading-4 hover:text-gray-500"
                                onClick={() => setOpen && setOpen(false)}
                            />
                        );
                    }

                })}
<AnchorLink to="/#contact" onClick={() => setOpen && setOpen(false)}>
                <Button

                    rounded
                    className="h-14 mt-6 text-white bg-purple px-8 py-4 rounded text-sm font-semibold block md-1:hidden"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </Popover.Group>
        </>
    );
};
const IconSearch = () => {
    return (
        <div className="mr-60px cursor-pointer">
            <img src={iconSearchHeader} className="w-5 h-5" alt="icon-search" />
        </div>
    );
};

const IconPhone= () => {
    return (
        <div className="mr-60px cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 font-bold" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
        </div>
    );
};


const ItemsMenuRight = ({ email, phone, singleLineAddress, setOpen, setOpenRequestMeeting }) => {
    return (
        <div className="hidden md-1:block border-l xl:border-0">
            <div className="hidden xl:flex items-center">
                <div className="flex">
                    {/* <IconSearch /> */}
                    <a href={`tel:${phone}`}><IconPhone /></a>
                </div>
                <Link to="/#contact">
                <Button

                    rounded
                    className="h-14 text-white bg-blue px-8 py-4 rounded text-sm font-bold"
                >
                   Contact Us
                </Button>
                </Link>
            </div>
            <div className="grid xl:hidden items-center pl-10">
                <div className="items-center text-left">
                    <div className="text-xs leading-7 h-6 text-gray-400">GET IN TOUCH</div>
                    <div className="font-semibold leading-7 h-6 text-2xl mb-7">Contact Us</div>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4">
                        { phone }
                    </p>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4 mb-4">
                        { email }
                    </p>
                    <p className="mt-3 text-normal not-italic tracking-normal leading-4">
                        { singleLineAddress }
                    </p>
                </div>
                <AnchorLink to="/#contact">
                <Button

                    rounded
                    className="h-14 mt-7 text-white bg-purple px-8 py-4 rounded text-sm font-semibold"
                >
                    Contact Us
                </Button>
                </AnchorLink>
            </div>
        </div>
    );
};

const NavbarComponent = ({ shadow = true, setOpenRequestMeeting, data: strapiData, ...props }) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const imageSrc = strapiData.logo?.url ? strapiData.logo.url : ''

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const [scrolled,setScrolled] = useState(false);

    const handleScroll = () => {
        if(typeof window != "undefined"){
            const offset = window.scrollY;
            if(offset > 150 ) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
        }



    }

    useEffect(() => {
        if(typeof window != "undefined"){
            window.addEventListener('scroll', handleScroll)
        }

    })

    return (
        <Popover className={`relative bg-white ${scrolled && `scrolled` }`}{...props}>
            <>
                <div
                    className={`absolute inset-0 shadow-md z-10 pointer-events-none`}
                    aria-hidden="true"
                />
                <div className="relative">
                    <div className=" flex max-w-screen-2xl mx-auto items-center justify-between h-auto xs:px-5 xs:pt-28px xs:pb-30px md-1:px-8
                    xl:py-6 xs:py-4 xl:pl-98px xl:pr-98px  md:justify-between  h-82px xl:h-120px">
                        <StyledLogo logo={strapiData.logo} />

                        <div className=" xl:hidden">
                            <div className="-my-2 flex">
                                {strapiData?.phone ? <a
                                  href={`tel:${strapiData?.phone}`}>
                                    <PhoneIcon className="h-8 w-8 mr-2 p-0.5"/>
                                </a> : <></>}
                                {strapiData?.email ? <a
                                  href={`mailto:${strapiData?.email}`}>
                                    <MailIcon className="h-8 w-8 mr-2"/>
                                </a> : <></>}
                            </div>
                        </div>

                        <div className="flex justify-end xl:hidden">
                            <div onClick={() => setOpen(true)} className="-my-2 -mr-2 xl:hidden">
                                <Popover.Button
                                    className={`inline-flex items-center justify-center text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-inset`}
                                >
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon
                                        className="w-8 h-8 text-blue-500"
                                        aria-hidden="true"
                                    />
                                </Popover.Button>
                            </div>
                        </div>


                        <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between">
                            <ItemsMenuLeft
                                navigations={props.navigations}
                                setOpenRequestMeeting={setOpenRequestMeeting}
                            />
                            <ItemsMenuRight
                                {...strapiData}
                                data={props}
                                setOpen={setOpen}
                                setOpenRequestMeeting={setOpenRequestMeeting}
                            />
                        </div>
                    </div>
                </div>

                <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        static
                        className="absolute h-screen md-1:h-auto top-0 inset-x-0 transition transform origin-top-right xl:hidden z-20"
                    >
                        <div className="h-full md-1:h-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white xl:divide-y-2 xl:divide-gray-50">
                            <div className="px-5 md-1:px-10 py-6 xl:px-5 space-y-6">
                                <div className="flex items-center justify-between">
                                    <StyledLogo logo={strapiData.logo} />
                                    <div onClick={() => setOpen(false)} className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            {/* <InputTemplate
                                classNames={{ wrapper: 'mx-5 md-1:mx-10' }}
                                rounded={true}
                                name="search"
                                id="search"
                                placeholder="Search"
                                onChange={handleChange}
                                value={search}
                                RightComponent={
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#6e4bd9"
                                        />
                                    </svg>
                                }
                            /> */}
                            <div className="pt-11 pb-14 px-5 md-1:px-10 xl:py-6 xl:px-5 space-y-6">
                                <div className="flex justify-between">
                                    <ItemsMenuLeft
                                        navigations={props.navigations}
                                        setOpen={setOpen}
                                        setOpenRequestMeeting={setOpenRequestMeeting}
                                    />
                                    <div className={`hidden xl:block ${styles.rectangle}`} />
                                    <ItemsMenuRight
                                        {...strapiData}

                                    />
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        </Popover>
    );
};

export default NavbarComponent;
